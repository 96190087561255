/* LoadingSpinner.css */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.spinner-logo {
  width: 100%;
  height: 100%;
  animation: spin 5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
